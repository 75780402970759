import React from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Particles from 'react-tsparticles'
import config from '../../assets/lib/particles-recruit.json'

const BusinessDevelopmentPageContent = () => (
  <div class="main">
    <section class="section">
      <div class="section__inner section__inner--recruit">
      <ul class="recruit-content">
        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">募集要項</h2>
          </div>
          <div class="recruit-content__table">
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">職種</div>
              <div class="recruit-content__cell">事業開発</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">勤務場所</div>
              <div class="recruit-content__cell">遠隔</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">勤務形態</div>
              <div class="recruit-content__cell"> フルタイム・パートタイムどちらも可能（週20時間以上）</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">契約形態</div>
              <div class="recruit-content__cell">雇用契約（社員）・業務委託契約どちらも可能</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">給与</div>
              <div class="recruit-content__cell">50万〜100万/月（※フルタイムの場合の金額）</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">必要なスキル等</div>
              <div class="recruit-content__cell">後述</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">試用期間</div>
              <div class="recruit-content__cell">3ヶ月</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">特記事項</div>
              <div class="recruit-content__cell">保険完備、副業可</div>
            </div>
          </div>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">業務内容</h2>
          </div>
          <h3 class="recruit-content__title-sub">新規事業の事業開発</h3>
          <p class="recruit-content__text">弊社では、現在以下の新規事業を立ち上げているところです。</p>
          <ul class="recruit-content__body">
            <li>ITエンジニア向けオンライン英会話</li>
            <li>初心者PM向けWebサービス</li>
          </ul>
          <p className="recruit-content__text">これらの事業を軌道に乗せ大きくするのが、第一の業務です。</p>

          <h3 class="recruit-content__title-sub">受託開発事業の拡大</h3>
          <p className="recruit-content__text">
            弊社には高スキル・バイリンガルのメンバーが多数在籍しています。
            また、関東以外の地方、あるいは海外在住のメンバーも多く、各メンバー経由で多くの国にネットワークがあります。
            以下、主なものを挙げます。
          </p>
          <ul className="recruit-content__body">
            <li>東南アジア（フィリピン、マレーシア、タイ、カンボジア）</li>
            <li>アメリカ大陸（アメリカ、カナダ、ブラジル）</li>
            <li>ヨーロッパ（スウェーデン、ロシア、オランダ、フランス）</li>
          </ul>
          <p className="recruit-content__text">
            こうした特色を活かして、受託開発事業を拡大、あるいは関連する新規事業の立ち上げ、というのが二番目にやって頂きたい内容です。
          </p>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">必須の条件</h2>
          </div>
          <h3 class="recruit-content__title-sub">１．以下に挙げた弊社の業務のいずれかに興味があること</h3>
          <ul class="recruit-content__body">
            <li>ソフトウェア受託開発・オフショア開発</li>
            <li>ITエンジニア向けオンライン英会話</li>
            <li>初心者PM向けWebサービス</li>
          </ul>
          <h3 class="recruit-content__title-sub">２．IT関連分野での事業開発、あるいは以下のような関連職種での実績・経験</h3>
          <ul class="recruit-content__body">
            <li>Webサービスのプロダクトマネージャー</li>
            <li>経営企画</li>
            <li>営業・マーケティングマネージャー</li>
          </ul>
          <h3 class="recruit-content__title-sub">３．英語力</h3>
          <ul class="recruit-content__body">
            <li>外国の方とやり取りすることもあります</li>
            <li>今後、オフショア開発を増やす予定です<br/>
            <a className="recruit-content__link"
               href="https://blog.mobalab.net/2018/08/20/will-use-offshore-development-more/" target="_blank">オフショア開発に本格的に取り組みます
              – もばらぶん</a>
            </li>
          </ul>
          <p class="recruit-content__annotation">※1〜3は and 条件です。</p>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">望ましい条件</h2>
          </div>
          <h3 class="recruit-content__title-sub">ソフトウェア開発の経験</h3>
          <p className="recruit-content__text">
            IT・ソフトウェア関連の事業のため、ソフトウェア開発の経験があると、社内や社外の人との会話がより円滑に進められると思います。
          </p>
        </li>
      </ul>

      </div>
    </section>
    <section class="section section--gray">
      <div class="recruit-content__buttons">
        <h3 class="recruit-content__buttons-annotation">「話を聞いてみたい」程度の方も含め、ご興味のある方はまずはご連絡ください。</h3>
        <a href="https://blog.mobalab.net/jobs/job-application-form/" className="btn-blue">採用応募フォーム</a>
        <a href="/recruit/" class="btn-back-to-recruit-list">採用情報一覧に戻る</a>
      </div>
    </section>
  </div>
)

const BusinessDevelopmentPage = (props) => (
  <Layout
    title="採用情報・事業開発（フルリモート） | 株式会社もばらぶ"
    description="株式会社もばらぶでは、現在フルリモートでの事業開発職を募集しています。裁量が大きく、新規事業をほぼ一から担当できます。ご興味のある方はお気軽にご応募ください。"
    pathName={props.location.pathname}
  >
    <Header />
    <div class="hero hero--job">
      <Particles className="hero__background hero__background--job" params={config}/>
      <div class="hero__title-area">
        <p class="hero__title-ja hero__title-ja--job">採用情報</p>
        <h1 class="hero__title hero__title--job">事業開発</h1>
      </div>
    </div>
    <BusinessDevelopmentPageContent />
  </Layout>
)
export default BusinessDevelopmentPage